<template>
  <div class="index" ref="index">
    <!-- <div class="top">
        <div class="left"><img src="../assets/img/index/title.png" alt=""></div>
        <div class="right"><img src="../assets/img/index/nav.png" alt=""></div>
      </div>
      <div class="swiper_content">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in photo1" :key="item" >
              <img :src=" require('../assets/img/index/'+item+'.jpg')" alt="">
            </div>
          </div>
          <div class="swiper-pagination"></div>
      </div>
      </div>
      <div class="kong"></div>
      <div class="nav">
        <div class="nav_item1">
          <div class="img"><img src="../assets/img/index/index.png" alt=""></div>
          <div class="text">首页</div>
        </div>
        <div class="nav_item2">
          <div class="img"><img src="../assets/img/index/about.png" alt=""></div>
          <div class="text">关于我们</div>
        </div>
        <div class="nav_item3">
          <div class="img"><img src="../assets/img/index/technology.png" alt=""></div>
          <div class="text">核心技术</div>
        </div>
        <div class="nav_item4">
          <div class="img"><img src="../assets/img/index/cooperate.png" alt=""></div>
          <div class="text">项目合作</div>
        </div>
      </div> -->
    <Header></Header>
    <div class="toTop" v-show="topBtnShow" @click="scrollToTop"></div>
    <div class="kong"></div>
    <div class="redBook_introduce" ref="redBookIntroduce">
      <div class="text1 " :class="animateFlag1 ? 'animate__animated animate__backInLeft ' : 'none'">
        <p>{{ this.Global.year }}年</p>
        <div>专注智能英语</div>
      </div>
      <div class="text2" :class="animateFlag1 ? 'animate__animated animate__backInLeft  animate_yc_half_1' : 'none'">
        <p>300万+</p>
        <div>孩子受益</div>
      </div>
      <div class="text3" :class="animateFlag1 ? 'animate__animated animate__backInLeft animate_yc_half_2' : 'none'">
        <p>1000家+</p>
        <div>体验中心</div>
      </div>
      <div class="text4" :class="animateFlag1 ? 'animate__animated animate__backInLeft animate_yc_half_3' : 'none'">
        <p>5项</p>
        <div>专利</div>
      </div>
      <div class="text5" :class="animateFlag1 ? 'animate__animated animate__backInLeft animate_yc_half_4' : 'none'">
        <p>39项</p>
        <div>软件著作权</div>
      </div>
    </div>
    <div class="video">
      <div class="title"><img src="../assets/img/index/hello_redbook.png" alt=""></div>
      <div class="publicize">
        <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
          :options="playerOptions"></video-player>
      </div>
    </div>
    <div class="kong"></div>
    <div class="father">
      <div class="memory_method" ref="redBookMemory" :class="animateFlag2 ? 'fixed' : ''">
        <div class="text">
          <div class="red">背单词 从未如此轻松</div>
          <div class="black">生词主动来找你 轻轻一点就记牢</div>
          <div class="jiange" v-if="winHeight > 800"></div>
          <div class="grey">
            <p>{{this.setnumberToChinese(this.Global.year)}}年技术结晶记忆引擎</p>
            <p>云端计算结合大数据分析，精确匹配记忆曲线</p>
            <p>生词在黄金记忆点 重复 重复 再重复</p>
            <p>3~7遍可形成母语式记忆</p>
          </div>
          <div class="jiange" v-if="winHeight > 800"></div>
        </div>

        <div class="memory_img"><img v-if="tab1" src="../assets/img/index/tab1.png" class="tab1" alt=""><img v-else
            src="../assets/img/index/tab2.png" alt=""></div>
      </div>
    </div>
    <div class="spoken" ref="spoken">
      <div class="title">练口语 就像智能外教在身边</div>
      <div class="spoken_text1">真人外教纯正发音 智能打分精准纠错</div>
      <div class="spoken_text_box">
        <p>背单词 学句子 练课文 读绘本</p>
        <p>口语练习贯穿学习全过程</p>
        <p>真人外教录制 纯正发音</p>
        <p>智能语音识别打分 精准纠错</p>
      </div>
      <div class="spoken_gif">
        <img src="../assets/img/index/spoken-gif.gif" alt="" v-if="animateFlag7">
        <img v-else src="../assets/img/index/spoken_gif.png" alt="">
      </div>
    </div>
    <div class="pyramid" ref="pyramid">
      <div class="title">与课堂同步 预习复习效率高</div>
      <div class="pyramid_text1">囊括以单词为核心的全部英语学习内容</div>
      <div class="pyramid_text_box">
        <img src="../assets/img/index/pyramid_text2.png" alt="">
      </div>
      <div class="pyramid_img">
        <div class="pyramid_content" :class="animateFlag3 ? 'animate__animated animate__fadeIn' : 'none'"><img
            src="../assets/img/index/pyramid.png" alt=""></div>
        <div class="pyramid_text" :class="animateFlag3 ? 'animate__animated animate__fadeIn animate_yc_half_5' : 'none'">
          <img src="../assets/img/index/pyramid_text.png" alt=""></div>
        <div class="people"><img src="../assets/img/index/pyramid_people.png" alt=""></div>
      </div>
    </div>
    <div class="ai" ref="ai">
      <div class="title">个性化 定制任务AI更懂你</div>
      <div class="ai_text1">每个孩子都有专属学习方案</div>
      <div class="ai_text_box">
        <p>智能测评捕捉知识薄弱点</p>
        <p>智能推送个性化学习内容</p>
        <p>基础、拓展、拔高、专项</p>
        <p>每个孩子都有专属训练路径</p>
      </div>
      <div class="ai_img">
        <div class="ai_text_img" :class="animateFlag4 ? 'animate__animated animate__fadeIn' : 'none'"><img
            src="../assets/img/index/ai_text.png" alt=""></div>
      </div>
    </div>
    <div class="game" ref="game">
      <div class="title">主动学 “游戏化”学习激发动力</div>
      <div class="game_text1">让不爱学英语的孩子 爱上学英语</div>
      <div class="game_content">
        <div class="game_text_box">
          <p>学习可以赚金币换礼品</p>
          <p>学后可以在游戏乐园玩中学</p>
          <p>化被动学习为主动</p>
        </div>
        <div class="game1" :class="animateFlag5 ? 'animate__animated animate__zoomIn' : 'none'"><img
            src="../assets/img/index/game_1.png" alt=""></div>
        <div class="game2" :class="animateFlag5 ? 'animate__animated animate__zoomIn' : 'none'"><img
            src="../assets/img/index/game_2.png" alt=""></div>
        <div class="game3" :class="animateFlag5 ? 'animate__animated animate__zoomIn' : 'none'"><img
            src="../assets/img/index/game_3.png" alt=""></div>
      </div>

    </div>
    <div class="kong"></div>
    <div class="oneForOne">
      <div class="content" ref="oneForOne" :class="animateFlag6 ? 'fixed2' : ''">
        <div class="title">1对1督学 就像老师坐在你身边</div>
        <div class="content_text1">学习有人管 三重保障家长放心</div>
        <div class="oneForOne_1" v-if="tab2 == 1">
          <div class="top">
            <div class="tip"><img src="../assets/img/index/oneForOne_tip.png" alt=""></div>
            <div class="text">家长放心之<span>1对1督学</span></div>
          </div>
          <div class="detail">
            <p>督学师随时介入管控学习，能够实时看到学习画面，</p>
            <p>实现实时进行语音通话，可以随时控制学习界面。</p>
          </div>
          <div class="img">
            <img src="../assets/img/index/oneForOne_1.png" alt="">
          </div>
        </div>
        <div class="oneForOne_1" v-else-if="tab2 == 2">
          <div class="top">
            <div class="tip"><img src="../assets/img/index/oneForOne_tip.png" alt=""></div>
            <div class="text">家长放心之<span>实时学习报告</span></div>
          </div>
          <div class="detail">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;学了多久、学习效率多少、学了什么内容、测</p>
            <p>试多少分，实时学习报告一一记录，家长随时可看，</p>
            <p>异常学情一眼看出来。</p>
          </div>
          <div class="img2">
            <img src="../assets/img/index/oneForOne_2.png" alt="">
          </div>
        </div>
        <div class="oneForOne_1" v-else-if="tab2 == 3">
          <div class="top">
            <div class="tip"><img src="../assets/img/index/oneForOne_tip.png" alt=""></div>
            <div class="text">家长放心之<span>专机专用封闭系统</span></div>
          </div>
          <div class="detail">
            <p>不能上外网，无法安装应用，封闭系统只能学习。</p>
            <p>定制输入法，取消联想功能防作弊，纯净防干扰。</p>
          </div>
          <div class="img3">
            <img src="../assets/img/index/oneForOne_3.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="kong"></div>
    <div class="school">
      <div class="school_text">
        <img src="../assets/img/index/school_text.png" alt="">
      </div>
      <div class="swiper_content swiper_content2">
        <div class="swiper swiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in photo2" :key="item">
              <img :src="require('../assets/img/index/' + item + '.jpg')" alt="">
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="kong"></div>
    <div class="ktz">
      <div class="title"><img src="../assets/img/index/ktz_title.png" alt=""></div>
      <div class="ktz_text">专为智能英语学习定制</div>
      <div class="ktz_img1"><img src="../assets/img/index/ktz_img1.png" alt=""></div>
      <div class="ktz_img2"><img src="../assets/img/index/ktz_img2.png" alt=""></div>
      <div class="ktz_img3"><img src="../assets/img/index/ktz_img3.png" alt=""></div>
    </div>
    <div class="kong"></div>
    <div class="student">
      <div class="img">
        <img src="../assets/img/index/student_img.png" alt="">
      </div>
    </div>
    <div class="lz">
      <div class="title"></div>
      <div class="lzBox">
        <div class="name">姓 名：</div>
        <div class="name_inp"><input type="text" v-model="name" placeholder="请输入您的姓名" /></div>
        <div class="phone">联系方式：</div>
        <div class="phone_inp"><input type="text" v-model="phone" placeholder="请输入您的联系方式" :maxlength="11"
            oninput="value=value.replace(/[^\d]/g,'')" pattern="[0-9]*" /></div>
        <div class="btn fireworks" @click="sendMsg"><img src="../assets/img/index/btn.png" alt=""></div>
      </div>
    </div>
    <FooterText :pageStage="1"></FooterText>
    <!-- <div class="bottom">
        <p>版权所有：北京红本科技有限公司</p>
        <p>地址：北京市海淀区上地信息路11号彩虹大厦北楼西段203室</p>
        <p>电话：4008-017-018</p>
      </div> -->
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination])
import axios from 'axios'
import { Toast } from 'vant';
import Header from "@/components/header";
import FooterText from './footerText.vue'
import 'swiper/swiper-bundle.css'
import { nextTick } from 'vue';

import numberToChinese from "../utils/numberToChinese"

export default {

  name: 'index',
  components: {
    Header,
    FooterText,
  },
  data() {
    return {
      topBtnShow: false,
      isScroll: false,
      isAndroid: false,
      winHeight: 0,
      name: '',
      phone: '',
      spokenHeight: 0,
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        currentTime: 200,
        sources: [
          {
            type: "",
            src: "https://sound.hssenglish.com/redbook/video/2023xuanchuanpian.mp4", //url地址
          },
        ],
        poster: require("../assets/img/index/video.png"), //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      photo1: ['index1', 'index2', 'index3', 'index4'],
      photo2: ['school1', 'school2', 'school3'],
      redBookIntroudceHeight: 0,
      animateFlag1: false,
      animateFlag2: false,
      animateFlag3: false,
      animateFlag4: false,
      animateFlag5: false,
      animateFlag6: false,
      animateFlag7: false,
      redBookmemoryHeight: 0,
      pyramidHeight: 0,
      aiHeight: 0,
      gameHeight: 0,
      oneForOneHeight: 0,
      tab1: true,
      tab2: 1,
      startY: 0,
      moveY: 0,
      overFlag: true,
      num: 0,
    }
  },
  created() {
  },
  mounted() {
    if (!this._isMobile()) {
      // 跳转至 pc 端路由
      // this.$router.push({
      //   path: '/'
      // })
      let app = document.querySelector('#app');
      app.style.width = "750px";
      app.classList.add("pcStyle");
    }
    let u = navigator.userAgent;
    this.isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
    // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端

    // //挂在后的去初始化swiper
    // new Swiper ('.swiper', {
    //   direction: 'horizontal', // 垂直切换选项
    //   loop: true, // 循环模式选项

    //   // 如果需要分页器
    //   pagination: {
    //     el: '.swiper-pagination',
    //     click:true,
    //   },
    //   autoplay: {
    //   delay: 2000,
    //   stopOnLastSlide: false,
    //   disableOnInteraction: true,
    //   },

    //   // 如果需要滚动条
    //   // scrollbar: {
    //   //   el: '.swiper-scrollbar',
    //   // },
    // })   
    this.winHeight = window.innerHeight
    document.querySelector('.father').style.height = window.innerHeight + 100 + 'px'
    document.querySelector('.oneForOne').style.height = window.innerHeight + 250 + 'px'
    this.redBookIntroudceHeight = this.$refs.redBookIntroduce.offsetTop
    this.redBookmemoryHeight = this.$refs.redBookMemory.offsetTop
    this.pyramidHeight = this.$refs.pyramid.offsetTop
    this.aiHeight = this.$refs.ai.offsetTop
    this.gameHeight = this.$refs.game.offsetTop
    this.oneForOneHeight = this.$refs.oneForOne.offsetTop
    this.spokenHeight = this.$refs.spoken.offsetTop
    window.addEventListener('scroll', this.handleTabFix, true)
  },
  methods: {
    // focusText(){
    //   if(this.isAndroid){
    //     this.isScroll = true;
    //     let height = window.pageYOffset;
    //     // alert(height)
    //     this.$nextTick(() => {
    //       window.scrollTo(0,height+300)
    //     });
    //   }

    // },
    sendMsg() {
      if (this.name && this.phone) {
        let num_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|16[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|19[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
        if (!num_reg.test(this.phone)) {
          Toast('请填写正确的手机号');
          return false;
        }
        if (this.name.length > 10) {
          Toast('姓名不可以大于10位');
          return false;
        }
        let data = {
          name: this.name,
          phone: this.phone,
          msg: "首页"
        };
        let formData = new FormData();
        for (let dataKey in data) {
          formData.append(dataKey, data[dataKey]);
        }
        let url = ""
        if (process.env.NODE_ENV == "production") {
          //生产环境
          url = "https://api.xiaohongben888.com/product/submitProductInquiry"
        } else {
          //本地环境
          url = "/api/product/submitProductInquiry"
        }

        axios.post(url, formData).then(function (response) {
          console.log(response);
          if (response.data.code == 200) {
            Toast('提交成功,客服经理将在24小时内与您联系。');
          }
        }).catch(function (error) {
          console.log(error);
        });
      } else {
        Toast('请填写完整信息');
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    scrollToTop() {
      // this.$refs.index.scrollTo({top: 0, behavior: 'smooth'});
      window.scrollTo(0, 0)
    },
    handleTabFix() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // let scrollTop;
      // if(this.$refs.index){
      //   scrollTop = this.$refs.index.scrollTop;
      // }
      let height = window.innerHeight;
      // console.log("scrollTop",scrollTop)
      if (scrollTop > height) {
        this.topBtnShow = true;
      } else {
        this.topBtnShow = false;
      }
      //动画1
      if (scrollTop > this.redBookIntroudceHeight) {
        this.animateFlag1 = true
      }
      //动画2
      if (scrollTop > this.redBookmemoryHeight && scrollTop < this.redBookmemoryHeight + 200) {
        this.animateFlag2 = true
      } else {
        this.animateFlag2 = false
      }
      if (scrollTop > this.redBookmemoryHeight && scrollTop < this.redBookmemoryHeight + 160) {
        this.tab1 = true
      }
      if (scrollTop > this.redBookmemoryHeight + 160 && scrollTop < this.redBookmemoryHeight + 200) {
        this.tab1 = false
      }
      if (scrollTop > this.redBookmemoryHeight + 200) {
        this.animateFlag2 = false
        this.overFlag = false
        document.querySelector('.father').style.position = 'relative'
        document.querySelector('.memory_method').style.position = 'absolute'
        document.querySelector('.memory_method').style.bottom = '0'
      } else {
        document.querySelector('.father').style.position = ''
        document.querySelector('.memory_method').style.position = ''
        document.querySelector('.memory_method').style.bottom = '0'
      }
      //gif
      if (scrollTop > this.spokenHeight - 60) {
        this.animateFlag7 = true
      }
      //动画3
      if (scrollTop > this.pyramidHeight) {
        this.animateFlag3 = true
      }
      //动画4
      if (scrollTop > this.aiHeight) {
        this.animateFlag4 = true
      }
      //动画5
      if (scrollTop > this.gameHeight) {
        this.animateFlag5 = true
      }
      //动画6
      if (scrollTop > this.oneForOneHeight && scrollTop < this.oneForOneHeight + 300) {
        this.animateFlag6 = true
      } else {
        this.animateFlag6 = false
      }
      if (scrollTop > this.oneForOneHeight && scrollTop < this.oneForOneHeight + 100) {
        this.tab2 = 1
      }
      if (scrollTop > this.oneForOneHeight + 100 && scrollTop < this.oneForOneHeight + 200) {
        this.tab2 = 2
      }
      if (scrollTop > this.oneForOneHeight + 200 && scrollTop < this.oneForOneHeight + 300) {
        this.tab2 = 3
      }
      if (scrollTop > this.oneForOneHeight + 300) {
        this.animateFlag6 = false
        document.querySelector('.oneForOne').style.position = 'relative'
        document.querySelector('.content').style.position = 'absolute'
        document.querySelector('.content').style.bottom = '0'
      } else {
        document.querySelector('.oneForOne').style.position = ''
        document.querySelector('.content').style.position = ''
        document.querySelector('.content').style.bottom = ''
      }
    },
    setnumberToChinese(num) {
      return numberToChinese(num)
    }
  }

}
</script>

<style lang="scss" scoped>
div,
span,
p {
  font-family: HarmonyOS Sans SC;
}

::v-deep .vjs-poster {
  background-size: 400px 225px;
  background-color: #fff;
}

.fireworks {
  animation: pulse 0.5s infinite;
}

@keyframes fireworks {
  0% {
    transform: scale(1);
    /*开始为原始大小*/
  }

  25% {
    transform: scale(1.3);
    /*放大1.1倍*/
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.3);
  }

}

.jiange {
  height: 7vh;
}

.van-toast__text {
  color: #fff;
}

.toTop {
  margin-top: -12vh;
  position: relative;
  z-index: 9999;
}

.kong {
  height: 6px;
  background-color: #f3f3f3;
}

body {
  background: #ccc !important;
}

.toTop {
  position: fixed;
  right: 20px;
  bottom: 150px;
  z-index: 100;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  background: url("../assets/img/project/top.png") round;
}

.index {

  // height:100vh;
  // overflow: auto;
  // overflow-x: hidden;
  // padding-top: 40px;
  >.top {
    padding: 0 20px 13.5px 23.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 195.5px;
      height: 29.5px;
      font-size: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right {
      width: 24px;
      height: 14.5px;
      font-size: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .nav {
    width: 100%;
    height: 162px;
    box-shadow: 2.5px -0.5px 5px 1.5px #a5a5a52e;
    background-color: #fff;
    // margin-top: 8.5px;
    display: flex;
    padding: 41.5px 28.5px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;

    .nav_item1 {

      .img {
        width: 38.5px;
        height: 34.5px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }

    .nav_item2 {
      .img {
        width: 36.5px;
        height: 36.5px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }

    .nav_item3 {
      .img {
        width: 36.5px;
        height: 36.5px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }

    .nav_item4 {
      .img {
        width: 48.5px;
        height: 32px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        font-size: 16.5px;
        text-align: center;
        color: #333333 100%;
      }
    }
  }

  .redBook_introduce {
    margin-top: 11px;
    width: 100%;
    height: 672.5px;
    background: url('../assets/img/index/redBook_introduce.png');
    background-size: 100% 672.5px;
    overflow: hidden;
    position: relative;

    .text1 {
      width: 120px;
      // margin-top: 254px;
      // margin-left: 100px;
      position: absolute;
      left: 100px;
      top: 254px;

      p {
        font-size: 21.94px;
        text-align: right;
        font-weight: 600;
        color: #DF0522;
      }

      div {
        font-size: 14px;
        margin-top: 4px;
        text-align: right;
        color: #333333 100%;
      }
    }

    .text2 {
      width: 120px;
      // margin-top: 28.5px;
      // margin-left: 10px;
      position: absolute;
      left: 10px;
      top: 48.49%;

      p {
        font-size: 21.94px;
        font-weight: 600;
        text-align: right;
        color: #DF0522;
      }

      div {
        font-size: 14px;
        margin-top: 4px;
        text-align: right;
        color: #333333 100%;
      }
    }

    .text3 {
      width: 120px;
      position: absolute;
      top: 61%;
      left: -16px;

      p {
        font-size: 21.94px;
        font-weight: 600;
        text-align: right;
        color: #DF0522;
      }

      div {
        font-size: 14px;
        margin-top: 4px;
        text-align: right;
        color: #333333 100%;
      }
    }

    .text4 {
      width: 120px;
      position: absolute;
      top: 74%;
      left: 0;

      // margin-top: 46px;
      // margin-left: 0px;
      p {
        font-size: 21.94px;
        font-weight: 600;
        text-align: right;
        color: #DF0522;
      }

      div {
        font-size: 14px;
        margin-top: 4px;
        text-align: right;
        color: #333333 100%;
      }
    }

    .text5 {
      width: 120px;
      position: absolute;
      top: 83%;
      left: 15%;

      p {
        font-size: 21.94px;
        font-weight: 600;
        text-align: right;
        color: #DF0522;
      }

      div {
        margin-top: 4px;
        font-size: 14px;
        text-align: right;
        color: #333333 100%;
      }
    }
  }

  .video {
    margin-top: 9.5px;
    padding-top: 20px;
    box-shadow: 0 2.5px 5px 1.5px #58585847;

    .title {
      width: 133px;
      height: 29px;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .publicize {
      width: 100%;
      height: 211px;
      margin-top: 13.5px;
    }
  }

  .fixed {
    position: fixed;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    // right: 0;
    top: 0;
    z-index: 99;
    background-color: #fff;
  }

  .fixed2 {
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 99;
    background-color: #fff;
  }

  .memory_method {
    width: 100%;

    .text {
      padding-top: 64px;
      // height: 247.5px;
      box-sizing: border-box;

      .red {
        font-size: 25px;
        text-align: center;
        font-weight: 600;
        color: #DF0522;
      }

      .black {
        font-weight: 600;
        font-size: 16.5px;
        text-align: center;
        color: #333333;
        margin-top: 10px;
      }

      .grey {
        margin-top: 20.5px;
        color: #999999;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        line-height: 28.79px;

        p {
          text-align: center;
        }
      }
    }

    .memory_img {
      margin-top: 12.5px;
      height: 407px;

      img {
        width: 100%;
        height: 100%;
      }

      .tab1 {
        box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;
      }
    }
  }

  .father {
    padding-bottom: 72px;
    box-sizing: border-box;
  }

  .spoken {
    width: 100%;
    height: 625px;
    background: url('../assets/img/index/spoken_bg.png');
    background-size: 100% 625px;
    box-sizing: border-box;
    box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;

    .title {

      font-weight: 600;
      font-size: 25px;
      text-align: center;
      color: #DF0522;
    }

    .spoken_text1 {
      font-weight: 600;
      font-size: 16.5px;
      text-align: center;
      color: #333333;
      margin-top: 10px;
    }

    .spoken_text_box {
      width: 190px;
      margin: 0 auto;
      margin-top: 37px;

      p {
        text-align: center;
        color: #999999;
        font-weight: 400;
        font-size: 14px;
        line-height: 29.29px;
      }
    }

    .spoken_gif {
      width: 375px;
      height: 375px;
      margin: 0 auto;
      margin-top: 26px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .pyramid {
    padding-top: 60px;

    .title {
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      color: #DF0522;
    }

    .pyramid_text1 {
      margin-top: 6px;
      font-weight: 600;
      font-size: 16.5px;
      text-align: center;
      color: #333333;
      margin-top: 10px;
    }

    .pyramid_text_box {
      width: 213px;
      height: 102px;
      margin: 0 auto;
      margin-top: 33px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .pyramid_img {
      height: 450px;
      position: relative;

      .pyramid_content {
        width: 375px;
        height: 290px;

        img {
          width: 100%;
          height: 100%;

        }
      }

      .pyramid_text {
        width: 75px;
        height: 171px;
        position: absolute;
        top: 53px;
        right: 96px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .people {
        width: 375px;
        height: 369px;
        position: absolute;
        top: 66px;

        img {
          width: 100%;
          height: 100%;

        }
      }
    }
  }

  .ai {
    width: 100%;
    height: 690px;
    padding-top: 50px;
    box-sizing: border-box;

    .title {
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      color: #DF0522;
    }

    .ai_text1 {
      font-weight: 600;
      font-size: 16.5px;
      text-align: center;
      color: #333333;
      margin-top: 7.5px;
    }

    .ai_text_box {
      width: 180px;
      margin: 0 auto;
      margin-top: 30px;

      p {
        text-align: center;
        color: #999999;
        font-weight: 500;
        font-size: 14px;
        line-height: 29.29px;
      }
    }

    .ai_img {
      margin-top: 60px;
      position: relative;
      width: 375px;
      height: 366px;
      background: url('../assets/img/index/ai_bgc.png');
      background-size: 375px 366px;
      box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;

      .ai_text_img {
        width: 90px;
        height: 189px;
        position: absolute;
        right: 29px;
        top: -15px;

        img {
          width: 100%;
          height: 100%;

        }
      }
    }
  }

  .game {
    width: 100%;
    height: 680px;
    padding-top: 50px;
    box-sizing: border-box;

    .title {
      font-weight: 700;
      font-size: 25px;
      text-align: center;
      color: #DF0522;
    }

    .game_text1 {
      font-weight: 600;
      font-size: 16.5px;
      text-align: center;
      color: #333333;
      margin-top: 10px;
    }

    .game_content {
      width: 375px;
      height: 542px;
      background: url('../assets/img/index/game_bg.png');
      background-size: 375px 542px;
      position: relative;

      .game1 {
        width: 53px;
        height: 16px;
        font-size: 0;
        position: absolute;
        top: 157px;
        left: 188px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .game2 {
        width: 126px;
        height: 45px;
        font-size: 0;
        position: absolute;
        top: 176px;
        left: 170px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .game3 {
        width: 129px;
        height: 53px;
        font-size: 0;
        position: absolute;
        top: 423px;
        left: 54px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .game_text_box {
        width: 180px;
        margin: 0 auto;
        margin-top: 37px;

        p {
          text-align: center;
          color: #999999;
          font-weight: 400;
          font-size: 14px;
          line-height: 29.29px;
        }
      }
    }
  }

  .oneForOne {
    padding-top: 60px;

    .content {

      box-sizing: border-box;

      .title {

        font-weight: 600;
        font-size: 25px;
        text-align: center;
        color: #DF0522;
      }

      .content_text1 {
        font-weight: 600;
        font-size: 16.5px;
        text-align: center;
        color: #333333;
        margin-top: 10px;
      }

      .oneForOne_1 {
        >.top {
          display: flex;
          align-items: center;
          padding-left: 29px;
          margin-top: 31px;

          .tip {
            width: 24.5px;
            height: 18.5px;
            font-size: 0;
            margin-right: 7px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            color: #333333;
            font-weight: 400;
            font-size: 14px;
            line-height: 29.29px;

            span {
              color: #df0522;
            }
          }
        }

        .detail {
          margin-top: 10px;
          color: #999999;
          font-weight: 400;
          font-size: 14px;
          text-align: left;
          padding-left: 31.5px;
          line-height: 29.29px;
        }

        .img {
          width: 375px;
          height: 360px;
          font-size: 0;
          margin-top: 43px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .img2 {
          width: 375px;
          height: 352px;
          font-size: 0;
          margin-top: 72px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .img3 {
          width: 375px;
          height: 328px;
          font-size: 0;
          margin-top: 63px;
          padding-bottom: 60px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .school {
    width: 100%;
    height: 674px;
    padding-top: 62px;
    box-sizing: border-box;

    .school_text {
      width: 318px;
      height: 349px;
      margin: 0 auto;
      font-size: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ktz {
    width: 100%;
    height: 999px;
    padding-top: 59px;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: 26.5px;
      font-size: 0;
      font-weight: 600;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .ktz_text {
      font-weight: 600;
      font-size: 16.5px;
      text-align: center;
      color: #333333;
      margin-top: 10px;
    }

    .ktz_img1 {
      width: 100%;
      height: 239px;
      font-size: 0;
      margin-top: 19px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .ktz_img2 {
      width: 318px;
      height: 283px;
      font-size: 0;
      margin: 0 auto;
      margin-top: 21px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .ktz_img3 {
      width: 100%;
      height: 266px;
      font-size: 0;
      margin-top: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .student {
    width: 100%;
    height: 382px;
    padding-top: 62px;
    box-sizing: border-box;

    .img {
      width: 326px;
      height: 290px;
      font-size: 0;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .lz {
    .title {
      width: 100%;
      height: 195px;
      background: url('../assets/img/index/shiyong.png');
      background-size: 100% 195px;
    }

    .lzBox {
      width: 346.66px;
      height: 269.02px;
      border-radius: 5px 5px 5px 5px;
      background: #ffffff;
      box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;
      margin: 0 auto;
      margin-top: -90px;
      padding: 30px 25px;
      box-sizing: border-box;

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        text-align: left;
      }

      .name_inp {
        margin-top: 9px;

        input {
          width: 296px;
          height: 37px;
          border: 1px solid #999;
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 14px;
          font-size: 14px;
        }
      }

      .phone {
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        text-align: left;
        margin-top: 21px;
      }

      .phone_inp {
        margin-top: 9px;

        input {
          width: 296px;
          height: 37px;
          border: 1px solid #999;
          border-radius: 5px;
          box-sizing: border-box;
          padding-left: 14px;
          font-size: 14px;
        }
      }

      .btn {
        width: 296px;
        height: 37px;
        font-size: 0;
        margin: 0 auto;
        margin-top: 25px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .bottom {
    padding: 36px 0 150px;

    p {
      color: #999999;
      font-weight: 400;
      font-size: 11.5px;
      text-align: center;
      line-height: 20.09px;
    }
  }
}

.school {
  .swiper2 {
    width: 319px;
    height: 195px;

    --swiper-pagination-color: #fff;

    /* 两种都可以 */
    .swiper-slide {
      width: 319px;
      height: 195px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.swiper {
  width: 375px;
  height: 293px;

  --swiper-pagination-color: #fff;

  /* 两种都可以 */
  .swiper-slide {
    width: 375px;
    height: 293px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.swiper_content2 {
  width: 319px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 17px;
}

.none {
  display: none;
}

:root {
  --animate-duration: 1s;
}

.animate_yc_half_1 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}

.animate_yc_half_2 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  --animate-delay: 1s;
}

.animate_yc_half_3 {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
  --animate-delay: 1.5s;
}

.animate_yc_half_4 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
  --animate-delay: 2s;
}

.animate_yc_half_5 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}
</style>
<style lang="scss">
.pcStyle {
  // width:750px;
  position: relative;
  margin: 0 auto;
}

.van-toast {
  .van-toast__text {
    color: #fff;

  }
}
</style>